
      export default {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 577.638 525.052"}, attrs),
              ...rest,
            },
            children.concat([_c('g',{attrs:{"id":"Warstwa_4"}},[_c('path',{attrs:{"fill":"#0565d8","d":"M288.819 236.327V405.306l100.962 58.291V294.618z"}}),_c('path',{attrs:{"fill":"#055aad","d":"M187.856 294.618v168.979l100.963-58.291V236.327z"}}),_c('path',{attrs:{"fill":"#50a5f9","d":"M389.781 61.456l-100.962 58.29-100.963-58.29-100.962 58.29 100.962 58.291 100.963 58.29 100.962-58.29 100.963-58.291z"}}),_c('path',{attrs:{"fill":"#2b94fc","d":"M187.856 178.037L86.894 119.746v285.56l100.962 58.291V294.618l100.963 58.291V236.327z"}}),_c('path',{attrs:{"fill":"#127bf9","d":"M389.781 178.037l-100.962 58.29v116.582l100.962-58.291v168.979l100.963-58.291v-285.56z"}})])])
          )
        }
      }
    