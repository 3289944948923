
      export default {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}, attrs),
              ...rest,
            },
            children.concat([_c('defs',[_c('linearGradient',{attrs:{"id":"a","y2":"771.51","gradientUnits":"userSpaceOnUse","x2":"527.72","y1":"771.51","x1":"84.315"}},[_c('stop',{attrs:{"stop-color":"#4a9ea1","offset":"0"}})]),_c('linearGradient',{attrs:{"id":"b","y2":"771.48","gradientUnits":"userSpaceOnUse","x2":"512.36","y1":"771.48","x1":"99.675"}},[_c('stop',{attrs:{"stop-color":"#63d3d7","offset":"0"}})])]),_c('path',{attrs:{"d":"M84.315 899.51l221.7 128 221.7-128v-256l-221.7-127.99-221.7 128z","fill":"url(#a)","transform":"translate(-50.017 -515.51)"}}),_c('path',{attrs:{"fill":"url(#b)","d":"M283.13 546.35l-160.74 92.806a38.396 38.396 0 010 8.59l160.75 92.805c13.554-10 32.043-10 45.597 0l160.75-92.807a38.343 38.343 0 01-.001-8.588l-160.74-92.806c-13.554 10.001-32.044 10.001-45.599 0zm221.79 127.03L344 767.22c1.884 16.739-7.361 32.751-22.799 39.489l.18 184.58a38.386 38.386 0 017.439 4.294l160.75-92.805c-1.884-16.739 7.36-32.752 22.799-39.49v-185.61a38.397 38.397 0 01-7.44-4.294zm-397.81 1.032a38.387 38.387 0 01-7.438 4.295v185.61c15.438 6.738 24.683 22.75 22.799 39.489l160.74 92.806a38.4 38.4 0 017.44-4.295v-185.61c-15.439-6.738-24.684-22.75-22.8-39.49l-160.74-92.81z","transform":"translate(-50.017 -515.51)"}}),_c('path',{attrs:{"d":"M256 512l221.7-128V128L256 256v256z","fill-opacity":".251"}}),_c('path',{attrs:{"d":"M256 512V256L34.3 128v256L256 512z","fill-opacity":".039"}}),_c('path',{attrs:{"d":"M34.298 128l221.7 128 221.7-128-221.7-128-221.7 128z","fill-opacity":".13"}})])
          )
        }
      }
    