
      export default {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"fill":"none","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 116 135"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M58.291 0L0 33.518v67.035l58 33.517 58-33.517V33.518L58.291 0zm53.92 44.593l-33.226-27.98 33.518 19.236v8.744h-.292zM13.407 63.246L25.94 28.563l8.16-1.457-20.693 36.14zm23.025-34.1h43.719l21.859 37.89-21.86 37.889H36.433l-21.86-37.89 21.86-37.89zM11.658 67.909l20.694 36.141L8.744 75.779l2.914-7.87zm65.578 39.93l-36.14 6.412-5.247-6.412h41.387zm25.94-37.015l-12.533 34.684-8.16 1.457 20.693-36.14zm1.749-4.663L83.94 30.02l23.608 28.27-2.623 7.87zm-65.87-39.93l36.141-6.412 5.246 6.412H39.055zm35.85-9.035l-44.01 7.87 37.014-13.408 6.995 5.538zM23.316 27.688L8.16 69.658l6.995-39.055 8.16-2.915zM6.704 77.528l28.562 34.392L4.955 86.271l1.749-8.743zm34.974 39.638l44.01-7.87-37.306 13.408-6.704-5.538zm51.588-10.784l15.156-41.97-6.995 39.055-8.16 2.915zm16.613-49.84l-28.854-34.1L111.337 47.8l-1.458 8.744zM65.869 9.036L24.774 23.9 58.29 4.663l7.578 4.372zM11.95 31.477L4.08 74.613V35.85l7.87-4.372zm-7.869 58l33.518 27.98L4.08 98.221v-8.744zm46.634 35.558l41.095-14.864-33.517 19.236-7.578-4.372zm53.919-22.442l7.578-43.136v38.764l-7.578 4.372z","fill":"url(#paint0_linear)"}}),_c('defs',[_c('linearGradient',{attrs:{"id":"paint0_linear","x1":".136","y1":"67.052","x2":"116.42","y2":"67.052","gradientUnits":"userSpaceOnUse"}},[_c('stop',{attrs:{"stop-color":"#22F2AD"}}),_c('stop',{attrs:{"offset":"1","stop-color":"#AB21F4"}})])])])
          )
        }
      }
    